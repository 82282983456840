.control-label {
	font-weight: 600;
}

.alert-danger {
	background-color: #a94442;
	border: 0;
	color: #fff;
}

.btn-outline {
	background: none;
	border: 1px solid rgba(255,255,255,0.5);

	&:hover, &:focus {
		border-color: rgba(255,255,255,1);
		color: #fff;
	}
}

.btn-circle {
	border-radius: 50%;
}

.btn-lg {
	padding: 12px 24px;
    font-size: 1.6em;
}