.dashboard {
	.dashboard-header {
		.btn {
			margin-top: 30px;
		}
	}
	#project-images {
		//border: 1px solid @gray-lighter;
		//border-radius: 5px;
		//padding: 15px;
		margin-bottom: 15px;

		.image {
			background: lighten(@gray-lighter, 5%);
			border: 1px solid darken(@gray-lighter, 5%);
			border-radius: @border-radius-base;
			padding: 15px;
			margin-bottom: 10px;
			.clearfix();

			.btn-image-destroy {
				float: right;

			}
		}
	}
}