.calc(@prop; @val) {
	@{prop}: calc(~'@{val}');
	@{prop}: -moz-calc(~'@{val}');
	@{prop}: -webkit-calc(~'@{val}');
	@{prop}: -o-calc(~'@{val}');
}

.preserve-3d() {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.vertical-align() {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}