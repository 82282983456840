@font-face {
	font-family: GibsonFontFace;
	font-weight: 200;
	src: url('../fonts/Gibson-Light.otf') format("opentype");
}

@font-face {
	font-family: GibsonFontFace;
	font-weight: 200;
	font-style: italic;
	src: url('../fonts/Gibson-LightIt.otf') format("opentype");
}

@font-face {
	font-family: GibsonFontFace;
	src: url('../fonts/Gibson-Regular.otf') format("opentype");
	font-weight: 400;
}

@font-face {
	font-family: GibsonFontFace;
	src: url('../fonts/Gibson-Italic.otf') format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: GibsonFontFace;
	src: url('../fonts/Gibson-SemiBold.otf') format("opentype");
	font-weight: 600;
}

@font-face {
	font-family: GibsonFontFace;
	src: url('../fonts/Gibson-SemiBoldIt.otf') format("opentype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: GibsonFontFace;
	font-weight: 700;
	src: url('../fonts/Gibson-Bold.otf') format("opentype");
}

@font-face {
	font-family: GibsonFontFace;
	font-weight: 700;
	font-style: italic;
	src: url('../fonts/Gibson-BoldItalic.otf') format("opentype");
}