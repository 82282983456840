.sub-header {
	margin-left: -15px;
	margin-right: -15px;

	a.filter-collapse-button {
		width: 100%;
		background: @gray-lighter;
		height: 80px;
		padding: 30px;
		font-size: 1.0em;
		letter-spacing: 1.5px;
		display: inline-block;
		color: @gray-dark;
		text-transform: uppercase;
		text-align: center;
	}

	ul.sub-header-list {
		list-style: none;
		margin: 0;
		padding: 0;

		li a {
			display: inline-block;
			float: left;
			width: 33.33%;
			text-align: center;
			padding-top: 30px;
			height: 80px;
			background-color: @gray-lighter;
			color: @gray-dark;
			text-transform: uppercase;
			font-size: 1.0em;
			letter-spacing: 1.5px;
			transition-duration: 0.3s;

			&.filter-color-1 {
				background: darken(@gray-lighter, 8%);
			}

			&.filter-color-2 {
				background: darken(@gray-lighter, 16%);
			}

			&.filter-color-3 {
				background: darken(@gray-lighter, 24%);
			}

			&:hover {
				background-color: #fff;
				color: @brand-primary;
			}

			&.hover-orange:hover {
				color: @brand-orange;
			}
			&.hover-teal:hover {
				color: @brand-teal;
			}
			&.hover-yellow:hover {
				color: @brand-yellow;
			}
		}
	}
}

.sub-header ul.project-filters {
	li a {
		width: 25%;
		padding-top: 30px;
		font-size: 1.0em;
		letter-spacing: 1.5px;
		height: 80px;

		&.filter-color-1 {
			background: darken(@gray-lighter, 8%);
		}

		&.filter-color-2 {
			background: darken(@gray-lighter, 16%);
		}

		&.filter-color-3 {
			background: darken(@gray-lighter, 24%);
		}

		&:hover, &:active, &.active {
			color: @brand-green;
			background-color: #fff;
		}
	}

	li a.filter-category-all {
		height: 160px;
		padding-top: 70px;
	}
}

@media all and (min-width: @screen-lg-min) {
	.sub-header ul.sub-header-list li a {
		height: 90px;
	}
	.sub-header ul.sub-header-list li a.filter-category-all {
		height: 180px;
	}
}

@media all and (max-width: @screen-xs-max) {
	.sub-header {
		ul.sub-header-list {
			li a {
				//height: 60px;
				//padding-top: 20px;
				font-size: 0.95em;
			}
		}
	}
	.sub-header {
		a.filter-collapse-button {
			height: 50px;
			padding: 15px;
		}
	}
	.sub-header ul.project-filters {
		li a.filter-category-all {
			width: 100%;
			height: 50px;
			padding: 15px;
			background: darken(@gray-lighter, 10%);
		}
		li a {
			width: 100%;
			height: 50px;
			padding: 15px;

			&.filter-color-1, &.filter-color-2, &.filter-color-3 {
				background: darken(@gray-lighter, 10%);
			}

			&:hover, &:active, &.active {
				color: @brand-green;
				background-color: #fff;
			}
		}
	}

}