@-webkit-keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	40% { opacity: 0.3; }
	60% { opacity: 0.5; }
	80% { opacity: 0.9; }
	100% { opacity: 1; }
}

@keyframes fadeIn {
	0% { opacity: 0; }
	20% { opacity: 0; }
	40% { opacity: 0.3; }
	60% { opacity: 0.5; }
	80% { opacity: 0.9; }
	100% { opacity: 1; }
}