.boxes {
	overflow: hidden;
	position: relative;
	height: 100%;

	& > a {
		position: absolute;
		width: 100%;
		height: 100%;

		&.anchor-reset-width {
			width: inherit;
		}

		& > .box {
			position: absolute;
		}
	}

	&.boxes-bg-green {
		background-color: @brand-green;
	}
}

.box-container {
	margin-left: -15px;
	margin-right: -15px;
	position: relative;
}



.box {
	overflow: hidden;
	position: relative;
	//top: 0;
	//bottom: 0;
	//right: auto;
	padding: 0;
	height: 100%;
	color: @gray-dark;
	transition-duration: 0.3s;

	&.box-full-height {
		margin-bottom: -999px;
		padding-bottom: 999px;
	}

	&.row-12 {
		margin-bottom: 0;
		padding-bottom: 100%;
	}
	&.row-9 {
		margin-bottom: 0;
		padding-bottom: 75%;
	}
	&.row-6 {
		margin-bottom: 0;
		padding-bottom: 50%;
	}
	&.row-4 {
		margin-bottom: 0;
		padding-bottom: 33.33%;
	}
	&.row-3 {
		margin-bottom: 0;
		padding-bottom: 25%;
	}
	&.row-2 {
		margin-bottom: 0;
		padding-bottom: 17.75%;
	}
	&.row-11 {
		margin-bottom: 0;
		padding-bottom: 11%;
	}
	&.row-1 {
		margin-bottom: 0;
		padding-bottom: 8.333%;
	}
	&.row-0 {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	&.row-custom {
		margin-bottom: 0;
		padding-bottom: 21.28%;
	}

	&:hover .box-bottom-left hr {
		width: 100%;
	}

	&.box-testimonial {
		background-image: url('/assets/images/quote_image.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		color: #fff;
		margin-bottom: 0;
		padding-bottom: 0;

		.box-inner {
			padding: 15% 7.5%;
			text-align: center;
		}
	}

	h1, h2, h3 {
		margin-top: 0;
		margin-bottom: 25px;
	}

	a.box-info {
		margin: 0;
		text-align: center;
		color: #fff;
	}

	.box-inner {
		width: 100%;
		height: 100%;
		padding: 7.5%;
		max-width: 1200px;
		margin: 0 auto;

		h4 {
			line-height: 2.2;
		}

		ul {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;

			h6 {
				margin-bottom: 15px;
				font-size: 0.9em;
			}
		}

		img.img-icon {
			display: inline;
			width: 35%;
			margin-top: 30px;
			margin-bottom: 30px;
		}

		img.img-icon-lg {
			display: inline;
			width: 50%;
			margin-top: 25%;
			margin-bottom: 25%;
		}
	}

	.box-bottom-left {
		position: absolute;
		bottom: 10%;

		hr {
			transition-duration: 0.3s;
			width: 30%;
			margin: 0;
		}
	}
}
	.box-image {
//		position: absolute;
//		top: 0;
//		left: 0;
//		right: 0;
//		bottom: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		&.box-image-contain {
			background-size: contain;
		}

		img {
			display: block;
			min-width: 100%;
		}
	}


.box-with-caption {
	margin-bottom: 0;
	padding-bottom: 0;

	&:hover {
		.box-caption {
			transition-delay:0s;
			display: block;
			background: rgba(0,0,0,0.8);
			top: 0;
			-webkit-animation-name: fadeIn;
			-webkit-animation-duration: 1s;
			animation-name: fadeIn;
			animation-duration: 0.3s;
		}
	}
	.box-caption {
		transition-duration: 0.3s;
		background: rgba(0,0,0,0);
		position: absolute;
		width: 100%;
		height: 100%;
		padding: 10%;
		display:none;
		.preserve-3d();

		.box-caption-inner {
			.vertical-align();
		}

		.box-title {
			font-size: 2.2em;
		}
	}
}

.box-hover:hover {
	transition-duration: 0.3s;
	opacity: 0.8;
}

.box-bg-color(@c) {
	background-color: @c;
	color: #fff;
}

.box-bg-white {
	.box-bg-color(#fff);
	color: @text-color;
	hr {
		border-color: @text-color;
	}
	.box-bottom-left a { color: #000;}
}
.box-bg-gray {.box-bg-color(@gray);}
.box-bg-gray-light {.box-bg-color(@gray-light)}
.box-bg-gray-lighter {
	.box-bg-color(@gray-lighter);
	color: @text-color;
	hr {
		border-color: @text-color;
	}
}
.box-bg-gray-lightest {
	.box-bg-color(lighten(@gray-lighter, 5%));
	color: @text-color;
	hr {
		border-color: @text-color;
	}
}
.box-bg-gray-dark {.box-bg-color(@gray-dark)}
.box-bg-gray-darker {.box-bg-color(@gray-darker)}
.box-bg-brand-primary {.box-bg-color(@brand-primary)}
.box-bg-brand-primary-light {.box-bg-color(@brand-primary-light)}
.box-bg-brand-primary-lighter {.box-bg-color(@brand-primary-lighter)}
.box-bg-brand-orange {.box-bg-color(@brand-orange)}
.box-bg-brand-green {.box-bg-color(@brand-green)}
.box-bg-brand-teal {.box-bg-color(@brand-teal)}
.box-bg-brand-purple {.box-bg-color(@brand-purple)}
.box-bg-brand-yellow {.box-bg-color(@brand-yellow)}
.box-bg-brand-orange-light{.box-bg-color(@brand-orange-light)}
.box-bg-brand-green-light{.box-bg-color(@brand-green-light)}
.box-bg-brand-teal-light{.box-bg-color(@brand-teal-light)}
.box-bg-brand-purple-light{.box-bg-color(@brand-purple-light)}
.box-bg-brand-yellow-light{.box-bg-color(@brand-yellow-light)}

a .box-bg-white:hover {
	.box-bg-color(@brand-primary);
	color: #fff;
	cursor: pointer;
	hr {
		border-color: #fff;
	}
}
a .box-bg-white-to-gray:hover {
	.box-bg-color(darken(@gray-lighter, 10%));
	color: @text-color;
	cursor: pointer;
	hr {
		border-color: @text-color;
	}
}
a .box-bg-gray:hover {cursor: pointer; .box-bg-color(lighten(@gray, 10%));}
a .box-bg-gray-light:hover {cursor: pointer; .box-bg-color(lighten(@gray-light, 10%));}
a .box-bg-gray-lighter:hover {cursor: pointer; .box-bg-color(darken(@gray-lighter, 10%)); color: @text-color;}
a .box-bg-gray-dark:hover {cursor: pointer; .box-bg-color(lighten(@gray-dark, 10%));}
a .box-bg-gray-darker:hover {cursor: pointer; .box-bg-color(lighten(@gray-darker, 10%));}
a .box-bg-brand-primary:hover {cursor: pointer; .box-bg-color(lighten(@brand-primary, 10%));}
a .box-bg-brand-primary-light:hover {cursor: pointer; .box-bg-color(lighten(@brand-primary-light, 10%));}
a .box-bg-brand-primary-lighter:hover {cursor: pointer; .box-bg-color(lighten(@brand-primary-lighter, 10%));}
a .box-bg-brand-purple:hover {cursor: pointer; .box-bg-color(lighten(@brand-purple, 10%));}

.boxes > a.anchor-height-50 {
	height: 50%;
}

.boxes > a.anchor-margin-top-25 {
	margin-top: 25%;
}

@media(min-width: @screen-lg-min) {
	a.anchor-absolute.anchor-tablet-relative {
		left: 0;
	}
	.box .box-inner {
		padding: 10%;
	}
}

@media(min-width: @screen-md-min) {
	.box .box-inner .half-width {
		max-width: 60%;
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.box-with-caption .box-caption .box-title {
		font-size: 1.8em;
	}
}

@media (max-width: @screen-sm-max) {
	.box.box-full-height {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.box .box-inner img.img-icon-lg {
		width: 35%;
		margin-top: 30px;
		margin-bottom: 30px;
	}
}

@media(max-width: @screen-sm-max) {
	.boxes > a, .boxes > a > .box {
		position: relative;

		.box-bottom-left {
			position: relative;
			margin-top: 40px;

			hr {
				width: 20px;
			}
		}
	}
}

@media(min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
	.boxes > a, .boxes > a > .box {
		&.anchor-absolute {
			position: absolute;
		}

		&.box-absolute {
			position: absolute;

			.box-bottom-left {
				position: absolute;
				margin-top: 0;
			}
		}
	}
}

@media(max-width: @screen-md-max) {
	.boxes > a, .boxes > a > .box {
		&.anchor-absolute.anchor-tablet-relative {
			position: relative;
		}
		&.box-absolute.box-tablet-relative {
			position: relative;
		}
	}
	a.anchor-pull-left {
		left: 0;
	}
}

@media(max-width: @screen-xs-max) {
 .box .box-inner.text-center h2 {
	 font-size: 1.6em;
 }
}

//@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
//	.box-with-caption .box-caption .box-title {
//		font-size: 2.2em;
//	}
//}