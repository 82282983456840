@import (inline) "../../../vendor/bower_components/owl.carousel/dist/assets/owl.carousel.css";
@import (inline) "../../../vendor/bower_components/owl.carousel/dist/assets/owl.theme.default.css";

.owl-theme .owl-dots .owl-dot {
	.transition-duration(0.5s);

	&.active span, &:hover span {
		height: 2px;
		background: rgba(255, 255, 255, 1);
	}

	span {
		width: 60px;
		height: 2px;
		background: rgba(255, 255, 255, 0.4);
		border-radius: 0;
		.transition-duration(0.5s);
	}
}

//.owl-carousel {
//	position: relative;
//	width: 100%;
//	height: 100%;
//}
//
//.owl-carousel .owl-item img{
//	display: block;
//	width: 100%;
//	height: 100%;
//}
//
//.owl-carousel .owl-item {
//	height: 100%;
//	width: 100%;
//	margin: 0;
//}

.owl-carousel {
	// .calc(height; "100% - 100px");
	height: 100%;
}
.owl-stage-outer, .owl-stage, .owl-item {
	height: 100%;
}

.owl-carousel .slide {
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	overflow: hidden;
	zoom: 1;
	height: 100%;
	width: 100%;
	color: #fff;
	display: table;
	position: relative;
	z-index: 2;
	-webkit-transform: translateZ(0);

	.slide-info {
		text-align: center;
		display:table-cell;
		vertical-align:middle;
		padding-bottom: 80px;
		padding-right: 35%;
		padding-left: 35%;

		h1 {
			font-size: 66px;
			margin-bottom: 80px;
			letter-spacing: 2px;
			line-height: 64px;
		}

		h3 {
			text-transform: uppercase;
			font-size: 1.0em;
			letter-spacing: 1.5px;
		}

		hr {
			width: 20%;
			margin-top: 10px;
		}

		.slide-button {
			background: url('/assets/images/icon-outline.png');
			color: #fff;
			background-repeat: no-repeat;
			padding: 40px 40px;
			background-position: center top;
			background-size: contain;
			margin-top: 80px;
			font-size: 0.7em;

			&:hover {
				color: @brand-primary;
				background-image: url('/assets/images/icon-outline-hover.png');
			}
		}
	}
}

.owl-controls {
	position: absolute;
	bottom: 20px;
	width: 100%;
}

video.bgvid {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	z-index: -100;
	-webkit-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	background-repeat: no-repeat;
	background-size: cover;
}

@media all and (max-width: @screen-xs-max) {
	.owl-theme .owl-dots .owl-dot {
		span {
			width: 32px;
		}
	}
	.owl-carousel .slide .slide-info {
		padding-right: 10%;
		padding-left: 10%;
	}
	.owl-carousel .slide .slide-info h1 {
		font-size: 38px;
		line-height: 38px;
		margin-bottom: 50px;
		margin-top: 14px;
	}
}

@media all and (max-width: @screen-md-max) and (min-width: @screen-sm-min) {
	.owl-carousel .slide .slide-info {
		padding-right: 30%;
		padding-left: 30%;
	}
}