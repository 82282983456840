.modal-form {
	color: #fff;

	.form-control {
		color: #fff;
		border: 0;
		border-bottom: 1px solid #fff;
		border-radius: 0;
		box-shadow: 0;
		background: rgba(0,0,0,0);
		-webkit-appearance: none;
		appearance: none;
		-moz-appearance: none;
		-webkit-appearance: none;
		border-radius: 0;
		-moz-border-radius: 0;
		-webkit-border-radius: 0;
		outline: 0 !important;
		margin: 20px 0;
		font-weight: 100;
		font-style: italic;

		&::-webkit-input-placeholder {
			color: rgba(255,255,255,0.5);
		}

		&:-moz-placeholder { /* Firefox 18- */
			color: rgba(255,255,255,0.5);
		}

		&::-moz-placeholder {  /* Firefox 19+ */
			color: rgba(255,255,255,0.5);
		}

		&:-ms-input-placeholder {
			color: rgba(255,255,255,0.5);
		}

		&:focus {
			outline-color: transparent;
			outline-style: none;
			border-color: none;
			box-shadow: none;
			-webkit-box-shadow: none;
		}
	}
}

.modal-outline {
	background: none;
	box-shadow: none;
	border: none;

	.modal-icon {
		text-align: center;
		color: green;
		padding: 40px;

		i {
			font-size: 8em;
		}
	}

	.modal-header {
		.btn-circle {
			margin-top: -8px;
		}
	}

	.modal-footer, .modal-header {
		border: none;
	}
}