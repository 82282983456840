.sub-footer {
	h6 {
		margin-bottom: 50px;
	}

	.boxes > a {
		position: relative;

		.box {
			position: relative;

			.box-inner {
				padding: 9.5%;

				img {
					width: 64px;
				}
			}
		}
	}
}

.footer {
	padding: 20px;
	background: #000;

	h5 {
		color: #fff;
		font-size: 90%;
	}

	p {
		font-weight: 100;
		font-size: 80%;
		color: #fff;
	}

	a {
		color: #fff;
		.transition-duration(0.3s);

		&:hover {
			opacity: 0.5;
			text-decoration: underline;
		}
	}

	.footer-icon {
		margin-right: 10px;
		float: left;
	}

	.social-icons {
		text-align: right;

		img, i {
			margin-top: 15px;
			margin-left: 25px;
		}
		a {
			.transition-duration(0.3s);
		}
		a:hover {
			opacity: 0.5;
		}
	}
}

@media(min-width: @screen-lg-min) {
	.footer {
		.social-icons {
			i {
				margin-left: 40px;
			}
		}
	}
}

@media(max-width: @screen-sm-max) {
	.footer {
		.social-icons {
			text-align: center;
			i {
				margin: 12px;
			}
		}
		.footer-info {
			padding: 20px;
			text-align: center;
		}
		.footer-icon {
			float: none;
		}
	}
	.sub-footer h6 {
		font-size: 0.8em;
	}
}