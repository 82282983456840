html, body {
	height: 100%;
	background: #000;
}

body {
	margin-top: @navbar-height;
}

body.home {
	margin-top: 0;
}

.wrapper {
	background: #fff;
}

.container-bg-white {
	background: #fff;
}

//.row {
//	margin-left: -30px;
//	margin-right: -30px;
//}

a:hover, a:focus {
	text-decoration: none;
}

p {
	font-weight: 100;
}

b, strong {
	font-weight: 600;
}

p, dd, dt {
	letter-spacing: 1px;
	font-weight: 300;
}

dt {
	font-weight: normal;
	font-style: italic;
}

h1 {
	font-weight: 100;
	letter-spacing: 2px;
}

h2 {
	letter-spacing: 1px;
}

h4 {
	font-weight: 400;
	letter-spacing: 0.5px;
}

h5 {
	font-weight: 100;
	font-style: italic;
	text-transform: none;
	letter-spacing: 1px;
}

h6 {
	font-weight: 100;
	text-transform: uppercase;
	letter-spacing: 2.5px;
	margin-bottom: 30px;
	line-height: 1.4em;

	span {
		position: relative;
		padding: 0 0 10px 0;

		&:after {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 1px;
			border-width: 0 0 1px;
			border-style: solid;
		}
	}
}

.img-responsive {
	min-width: 100%;
}

video::-webkit-media-controls {
	display:none !important;
}

@media all and (max-width: 1800px) {
	body {
		font-size: 16px;
	}
}
@media all and (min-width: @screen-lg-min) and (max-width: 1799px) {
	body {
		font-size: 16px;
	}
}
@media all and (max-width: @screen-md-max) {
	body {
		font-size: 16px;
	}
}
@media all and (max-width: @screen-sm-max) {
	body {
		font-size: 14px;
	}
}
@media all and (max-width: @screen-xs-max) {
	body {
		font-size: 14px;
		margin-top: @navbar-height - 40;
	}
}