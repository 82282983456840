.navbar {
	border: none;
	transition-duration: 0.3s;

	.navbar-toggle .icon-bar {
		height: 1px;
	}
}
.navbar-brand > img {
	height: 70px;
	width: auto;
	margin-top: -24px;
}

.navbar-inverse {
	.navbar-nav {
		li {
			a {
				color: #fff;
				font-weight: 100;
				letter-spacing: 1.2px;

				&:hover {
					color: @brand-green;
				}

				&.hover-brand-blue:hover {
					color: @brand-blue;
				}

				&.hover-brand-green:hover {
					color: @brand-green;
				}

				&.hover-brand-teal:hover {
					color: @brand-teal;
				}

				&.hover-brand-purple:hover {
					color: @brand-purple;
				}

			}

		}
	}
}

.nav .nav-menu {
	position: absolute;
	top: 37px;
	float: none;
	right: 54px;

	li {
		display: inline;
		margin-left: 20px;
	}
}

.nav .nav-menu-button {
	padding-top: 20px;
	padding-bottom: 20px;
	margin: 15px;

	&:hover, &:active {
		color: #fff !important;
	}

	i {
		transition-duration: 0.5s;
	}
}

@media (max-width: @screen-xs-max) {
	.navbar {
		background-color: @gray-base !important;
		min-height: 60px;
	}
	.navbar-brand {
		height: 60px;
		padding: 30px 15px;
	}
	.navbar-brand > img {
		height: 40px;
		margin-top: -20px;
	}
	.navbar-toggle {
		margin-top: 16px;
		margin-bottom: 16px;
	}
	.navbar-inverse .navbar-toggle {
		border: none;
	}
	.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
		background: none;
	}
	.navbar-collapse {
		border: none;
		box-shadow: none;
	}
	.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
		background: #000;
	}
	.nav li {
		text-align: center;
	}
}